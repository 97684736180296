import { SensorTagId } from "models/samplePoint";

export default function getMockMachineControls(deviceId, siteId) {
  // The device is at St Leonards
  return [
    {
      id: 434777,
      sid: "1918fa2d-1fe8-4192-aa13-7733b2b5d01e",
      name: "Mock Pump Control - Looping",
      maxPacketInterval: 25200,
      siteId,
      deviceId,
      userComments: "",
      operatorComments: "",
      assetTypeId: 17,
      lastSampleId: null,
      lastSampleDate: null,
      lastSampleData: {},
      deviceTags: {
        make: "Farmbot",
        model: "HolyKell 0-600 5vdc",
        portNo: 7,
        channel: 0,
        internal: 0,
        lastPortNo: 0,
        assetTypeId: 17,
        activeStatus: 1,
        serialNumber: -1,
        portMappingState: 0,
        lastPortMappingState: 0,
      },
      sensorTags: {
        id: 5001,
        type: "Machine Control",
        category: "Machine Control",
      },
      rwUnits: "",
      config: {
        automation: 0,
        controlPointId: 1434777,
      },
      createdAt: 1651795425633,
      updatedAt: 1651795425633,
      statusActive: 1,
      statusArchived: 0,
      lastConfigDate: 1644975471802,
      isConfigured: true,
      machineControl: {
        connectedToSamplePointSid: null,
      },
    },
    {
      id: 434888,
      sid: "1918fa2d-1fe8-4192-aa13-7733b2b5d01e",
      name: "Mock Pump Control - Off",
      maxPacketInterval: 25200,
      siteId,
      deviceId,
      userComments: "",
      operatorComments: "",
      assetTypeId: 17,
      lastSampleId: null,
      lastSampleDate: null,
      lastSampleData: {},
      deviceTags: {
        make: "Farmbot",
        model: "HolyKell 0-600 5vdc",
        portNo: 7,
        channel: 0,
        internal: 0,
        lastPortNo: 0,
        assetTypeId: 17,
        activeStatus: 1,
        serialNumber: -1,
        portMappingState: 0,
        lastPortMappingState: 0,
      },
      sensorTags: {
        id: 5001,
        type: "Machine Control",
        category: "Machine Control",
      },
      rwUnits: "",
      config: {
        automation: 0,
        controlPointId: 434888,
      },
      createdAt: 1651795425633,
      updatedAt: 1651795425633,
      statusActive: 1,
      statusArchived: 0,
      lastConfigDate: 1644975471802,
      isConfigured: false,
      machineControl: {
        connectedToSamplePointSid: null,
      },
    },
    {
      id: 434889,
      sid: "1918fa2d-1fe8-4192-aa13-7733b2b5d02e",
      name: "Mock Pump Control - On",
      maxPacketInterval: 25200,
      siteId,
      deviceId,
      userComments: "",
      operatorComments: "",
      assetTypeId: 17,
      lastSampleId: null,
      lastSampleDate: null,
      lastSampleData: {},
      deviceTags: {
        make: "Farmbot",
        model: "HolyKell 0-600 5vdc",
        portNo: 7,
        channel: 0,
        internal: 0,
        lastPortNo: 0,
        assetTypeId: 17,
        activeStatus: 1,
        serialNumber: -1,
        portMappingState: 0,
        lastPortMappingState: 0,
      },
      sensorTags: {
        id: 5001,
        type: "Machine Control",
        category: "Machine Control",
      },
      rwUnits: "",
      config: {
        automation: 0,
        controlPointId: 1434889,
      },
      createdAt: 1651795425633,
      updatedAt: 1651795425633,
      statusActive: 1,
      statusArchived: 0,
      lastConfigDate: 1644975471802,
      isConfigured: true,
      machineControl: {
        connectedToSamplePointSid: null,
      },
    },
    {
      id: 434890,
      sid: "1918fa2d-1fe8-4192-aa13-7733b2b5d02f",
      name: "Mock Pump Control - Turning on",
      maxPacketInterval: 25200,
      siteId,
      deviceId,
      userComments: "",
      operatorComments: "",
      assetTypeId: 17,
      lastSampleId: null,
      lastSampleDate: null,
      lastSampleData: {},
      deviceTags: {
        make: "Farmbot",
        model: "HolyKell 0-600 5vdc",
        portNo: 7,
        channel: 0,
        internal: 0,
        lastPortNo: 0,
        assetTypeId: 17,
        activeStatus: 1,
        serialNumber: -1,
        portMappingState: 0,
        lastPortMappingState: 0,
      },
      sensorTags: {
        id: 5001,
        type: "Machine Control",
        category: "Machine Control",
      },
      rwUnits: "",
      config: {
        automation: 0,
        controlPointId: 1434890,
      },
      createdAt: 1651795425633,
      updatedAt: 1651795425633,
      statusActive: 1,
      statusArchived: 0,
      lastConfigDate: 1644975471802,
      isConfigured: false,
      machineControl: {
        connectedToSamplePointSid: null,
      },
    },
    {
      id: 434891,
      sid: "1918fa2d-1fe8-4192-aa13-7733b2b5d02e",
      name: "Mock Pump Control - Turning Off",
      maxPacketInterval: 25200,
      siteId,
      deviceId,
      userComments: "",
      operatorComments: "",
      assetTypeId: 17,
      lastSampleId: null,
      lastSampleDate: null,
      lastSampleData: {},
      deviceTags: {
        make: "Farmbot",
        model: "HolyKell 0-600 5vdc",
        portNo: 7,
        channel: 0,
        internal: 0,
        lastPortNo: 0,
        assetTypeId: 17,
        activeStatus: 1,
        serialNumber: -1,
        portMappingState: 0,
        lastPortMappingState: 0,
      },
      sensorTags: {
        id: 5001,
        type: "Machine Control",
        category: "Machine Control",
      },
      rwUnits: "",
      config: {
        automation: 0,
        controlPointId: 1434891,
      },
      createdAt: 1651795425633,
      updatedAt: 1651795425633,
      statusActive: 1,
      statusArchived: 0,
      lastConfigDate: 1644975471802,
      isConfigured: true,
      machineControl: {
        connectedToSamplePointSid: null,
      },
    },
    {
      id: 434892,
      sid: "1918fa2d-1fe8-4192-aa13-7733b2b5d01f",
      name: "Mock Manual Pump Control - Off",
      maxPacketInterval: 25200,
      siteId,
      deviceId,
      userComments: "",
      operatorComments: "",
      assetTypeId: 17,
      lastSampleId: null,
      lastSampleDate: null,
      lastSampleData: {},
      deviceTags: {
        make: "Farmbot",
        model: "HolyKell 0-600 5vdc",
        portNo: 7,
        channel: 0,
        internal: 0,
        lastPortNo: 0,
        assetTypeId: 17,
        activeStatus: 1,
        serialNumber: -1,
        portMappingState: 0,
        lastPortMappingState: 0,
      },
      sensorTags: {
        id: 5001,
        type: "Machine Control",
        category: "Machine Control",
      },
      rwUnits: "",
      config: {
        automation: 0,
        controlPointId: 1434892,
      },
      createdAt: 1651795425633,
      updatedAt: 1651795425633,
      statusActive: 1,
      statusArchived: 0,
      lastConfigDate: 1644975471802,
      isConfigured: true,
      machineControl: {
        connectedToSamplePointSid: null,
      },
    },
    {
      id: 434893,
      sid: "1918fa2d-1fe8-4192-aa13-7733b2b5d01g",
      name: "Mock Dual Machine Control - Pump",
      maxPacketInterval: 25200,
      siteId,
      deviceId,
      userComments: "",
      operatorComments: "",
      assetTypeId: 17,
      lastSampleId: null,
      lastSampleDate: null,
      lastSampleData: {},
      deviceTags: {
        make: "Farmbot",
        model: "HolyKell 0-600 5vdc",
        portNo: 7,
        channel: 0,
        internal: 0,
        lastPortNo: 0,
        assetTypeId: 17,
        activeStatus: 1,
        serialNumber: -1,
        portMappingState: 0,
        lastPortMappingState: 0,
      },
      sensorTags: {
        id: SensorTagId.PRIMARY_MACHINE_CONTROL,
        type: "Machine Control",
        category: "Machine Control",
      },
      rwUnits: "",
      config: {
        automation: 1,
        controlPointId: 1434893,
      },
      controlPointTypeId: SensorTagId.PRIMARY_MACHINE_CONTROL,
      createdAt: 1651795425633,
      updatedAt: 1651795425633,
      statusActive: 1,
      statusArchived: 0,
      lastConfigDate: 1644975471802,
      isConfigured: true,
      machineControl: {
        connectedToSamplePointSid: "106e1667-41d9-46d5-bac9-00dd5acd104e",
      },
    },
    {
      id: 434894,
      sid: "1918fa2d-1fe8-4192-aa13-7733b2b5d01h",
      name: "Mock Dual Machine Control - Genset",
      maxPacketInterval: 25200,
      siteId,
      deviceId,
      userComments: "",
      operatorComments: "",
      assetTypeId: 17,
      lastSampleId: null,
      lastSampleDate: null,
      lastSampleData: {},
      deviceTags: {
        make: "Farmbot",
        model: "HolyKell 0-600 5vdc",
        portNo: 19,
        channel: 0,
        internal: 0,
        lastPortNo: 0,
        assetTypeId: 17,
        activeStatus: 1,
        serialNumber: -1,
        portMappingState: 0,
        lastPortMappingState: 0,
      },
      sensorTags: {
        id: SensorTagId.SECONDARY_MACHINE_CONTROL,
        type: "Machine Control",
        category: "Machine Control",
      },
      rwUnits: "",
      config: {
        automation: 1,
        controlPointId: 1434894,
      },
      controlPointTypeId: SensorTagId.SECONDARY_MACHINE_CONTROL,
      createdAt: 1651795425633,
      updatedAt: 1651795425633,
      statusActive: 1,
      statusArchived: 0,
      lastConfigDate: 1644975471802,
      isConfigured: true,
      machineControl: {
        connectedToSamplePointSid: null,
      },
    },
  ];
}
